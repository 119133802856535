<template>
  <iframe
      :src="'/static/plugins/txt/txt.html?bid='+bookId"
      title="7e8b772e15a88583d6fbd7dc01a901c23 - Koodo Reader"
      class="pdf-box">Loading
  </iframe>
</template>

<script>
/* eslint-disable */

export default {
  name: "vue_txt_preview",

  data() {
    return {
      txtInfo:'',
      matchContent:'',
      imgContent:'',
      bookId:'',
    };
  },
  watch: {},
  computed: {},
  created() {
    this.bookId = this.$route.query.bookId;
    setTimeout(() => {
      //异步执行列表刷新
      this.getBookInfo();
      this.addBook();
    }, 0)
  },
  methods: {
  },
};
</script>
<style>
.pdf-box {
  height: calc(99vh);
}
</style>

